import autolinker from 'autolinker'
import React, { useMemo } from 'react'
import Typography, { ITypograhyProps } from './common/Typography'
import { twMerge } from 'tailwind-merge'

interface IProps {
  as?: 'p' | 'span' | 'div'
  newWindow?: boolean
  breakLine?: boolean
  prewrap?: boolean
  lightWeight?: boolean
  lineLimit?: ITypograhyProps['truncate']
  children?: React.ReactNode
  className?: string
}

const Autolink: React.FC<IProps> = ({
  newWindow = false,
  as: Tag = 'p',
  breakLine = false,
  prewrap = false,
  lightWeight = false,
  lineLimit,
  className,
  children
}) => {
  const text = children as string

  const linkedText = useMemo(
    () =>
      autolinker.link(text, {
        newWindow,
        className: 'text-blue'
      }),
    [text, newWindow]
  )

  return (
    <Typography
      truncate={lineLimit}
      fontWeight={lightWeight ? 'light' : 'default'}
      component={Tag}
      dangerouslySetInnerHTML
      className={twMerge(
        breakLine && 'break-all',
        prewrap && 'whitespace-pre-wrap',
        className
      )}
      size={'sm'}
    >
      {linkedText}
    </Typography>
  )
}

export default Autolink
