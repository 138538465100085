import { NextPage } from 'next'
import NavbarLayout from 'components/NavbarLayout'
import ScreenLayout from 'components/ScreenLayout'
import CollectiveDetails from 'components/Collectives/CollectiveDetails/CollectiveDetails'
import { CollectiveProvider } from 'context/CollectiveContext'
import genGetServerSideProps, {
  ICollectivePageProps
} from 'lib/getServerSideProps/forCollectivePage'

const CollectiveDetailsPage: NextPage<ICollectivePageProps> = ({
  collectiveId
}) => {
  return (
    <CollectiveProvider collectiveId={collectiveId}>
      <NavbarLayout stuckClasses="bg-gray-100">
        <ScreenLayout>
          <CollectiveDetails />
        </ScreenLayout>
      </NavbarLayout>
    </CollectiveProvider>
  )
}

export default CollectiveDetailsPage

export const getServerSideProps = genGetServerSideProps('/', [], false)
